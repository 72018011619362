import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { BulkResumeSchema, ResumeSchema, ResumesSchema } from "../models/dto/resume.dto";

@Injectable({
    providedIn: 'root',
})
export class ResumeService extends AbstractHttpHandler {
    bulkResumeSchema$ = new BehaviorSubject<BulkResumeSchema>(undefined);
    resumesSchema$ = new BehaviorSubject<ResumesSchema>(undefined);
    resumeSchema$ = new BehaviorSubject<ResumeSchema>(undefined);
    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
    ) {
        super(http, apiEventsService);
    }

    getBulkResumes(
        page: number = 1,
        limit: number = 10,
        startDate: string = null,
        endDate: string = null,
        adminId: number = null
    ) {
        const url = `v2/admins/resumeLoads`;
        const eventType = ApiEventType.GET_BULK_RESUMES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();

        if (limit) {
            params = params.append('limit', limit);
        }

        if (page) {
            params = params.append('page', page);
        }

        if (startDate) {
            params = params.append('startDate', startDate);
        }

        if (endDate) {
            params = params.append('endDate', endDate);
        }

        if (adminId) {
            params = params.append('adminId', adminId);
        }

        // params = params.append('include', ['TOTAL'].toString());

        this.http.get<BulkResumeSchema>(url, { params })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe((response: BulkResumeSchema) => {
                this.bulkResumeSchema$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })
    }


    getResumes(
        resumeLoadId: number
    ) {
        const url = `v2/admins/resumeLoads/${resumeLoadId}`;
        const eventType = ApiEventType.GET_RESUMES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.get<ResumesSchema>(url)
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe((response: ResumesSchema) => {
                this.resumesSchema$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })
    }

    upladBulkResumes(name: string, resumes: any) {
        let params = new HttpParams();

        if (name) {
            params = params.append('name', name);
        }

        const url = `v2/admins/resumeLoads`;
        const eventType = ApiEventType.UPLOAD_RESUMES;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.post<any>(url, resumes, { params })
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe(response => {
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            });
    }

    getResume(
        resumeLoadId: number,
        resumeId : number
    ) {
        const url = `v2/admins/resumeLoads/${resumeLoadId}/resumes/${resumeId}`;
        const eventType = ApiEventType.GET_RESUME;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        this.http.get<ResumeSchema>(url)
            .pipe(catchError(this.handleErrors(eventType, [])))
            .subscribe((response: ResumeSchema) => {
                this.resumeSchema$.next(response);
                this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
            })
    }


    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
            const errorResponse = error.error;

            let title = Messages.HEADER_GENERIC_ERROR;
            let message = Messages.MESSAGE_GENERIC_ERROR;
            let showDialog = true;

            if (errorResponse) {
                const errorCode = errorResponse.error;
                switch (errorCode) {
                    case 'invalid_token': {
                        title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                        message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                        break;
                    }
                    case 'INVALID_INPUT': {
                        title = Messages.HEADER_INVALID_INPUT;
                        message = JSON.stringify(errorResponse.constraintViolations);
                        break;
                    }
                }
            }

            const errorToken = error.headers.get('Response_token');
            message = `${message} <br/> Response Token: ${errorToken}`;

            this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
            });
            this.unAuthorizedHandler(error)
            return response as T;
        };
    }

    protected unAuthorizedHandler(error: any) {
        if (error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }
}
