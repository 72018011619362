import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { JobSkillDto, JobSkillExperienceInputDto } from 'src/app/shared/models/dto/skill.dto';
import { ExperienceLevel, SkillService } from 'src/app/shared/services/skill.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ApiEventStatus, ApiEventType } from "../../../models/api-event";
import { ApiEventService } from "../../../services/api-event.service";
import { JobSkillExperienceDto } from "../../../models/dto/opentimer-job-profile.dto";
import { OpentimerJobProfileService } from "../../../services/opentimer-job-profile-service";
import { MessageService } from "primeng/api";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-skills',
  templateUrl: './edit-profile-skills.component.html',
  styleUrls: ['./edit-profile-skills.component.scss']
})
export class EditProfileSkillsComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() profileId: number;

  @Output() onClose = new EventEmitter();

  @Input() jobSkills: Array<JobSkillExperienceDto> = [];
  experienceLevels: Array<ExperienceLevel> = [];
  skills: Array<JobSkillDto> = [];

  readonly chipColor = SkillService.chipColor;

  skillForm = this.formBuilder.group({
    experienceLevel: this.formBuilder.control('', [Validators.required]),
    skillId: this.formBuilder.control(-1, [Validators.required]),
  });


  constructor(
    private formBuilder: FormBuilder,
    private skillService: SkillService,
    private opentimerJobProfileService: OpentimerJobProfileService,
    private messageService: MessageService,
    private apiEventService: ApiEventService
  ) { }

  ngOnInit(): void {
    this.getApiEvents();
    this.skillService.getSkills(null, -1, null, true);
    this.experienceLevels = this.skillService.getExperienceLevels();
    if (this.profileId) {
      this.opentimerJobProfileService.getJobProfileSkillExperienceLevel(this.profileId);
    }
  }

  protected getApiEvents(): void {
    if (this.apiEventService.event) {
      this.apiEventService.event
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          if (event && event.type === ApiEventType.GET_SKILLS && event.status === ApiEventStatus.COMPLETED) {
            const jobSkillSchema = this.skillService.jobSkillsSchema$.value;
            if (jobSkillSchema && jobSkillSchema.jobSkills) {
              this.skills = jobSkillSchema.jobSkills;
            }
          }
          if (event && event.type === ApiEventType.GET_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS && event.status === ApiEventStatus.COMPLETED) {
            const jobSkillSchema = this.opentimerJobProfileService.jobSkillLevels$.value;
            if (jobSkillSchema && jobSkillSchema.jobSkillExperienceLevels) {
              this.jobSkills = jobSkillSchema.jobSkillExperienceLevels;
            }
          }

          if (event && event.type === ApiEventType.ADD_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileSkillExperienceLevel(this.profileId);
            this.skillForm.reset();
            this.messageService.add({ severity: 'success', summary: 'Skill level added', detail: 'Job Skill level is added to this profile' });
          }

          if (event && event.type === ApiEventType.DELETE_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileSkillExperienceLevel(this.profileId);
            this.messageService.add({ severity: 'success', summary: 'Skill level deleted', detail: 'Job Skill level is deleted from this profile' });
          }
        });
    } else {
    }
  }

  addSkillLevel() {
    if (this.profileId) {
      this.opentimerJobProfileService.addJobProfileSkillExperienceLevel(this.profileId, this.skillForm.value as JobSkillExperienceInputDto);
    } else {
      const skill = this.skills.find(skill => skill.id == this.skillForm.value.skillId)
      this.jobSkills.push({ ...this.skillForm.value as JobSkillExperienceInputDto, id: skill.id, skill: skill.skill, translations: skill.translations })
    }
  }

  removeSkillLevel(skillLevelId: number, index: number) {
    if (this.profileId) {
      this.opentimerJobProfileService.deleteJobProfileSkillExperienceLevel(this.profileId, skillLevelId);
    } else {
      this.jobSkills.splice(index, 1)
    }
  }

  getSkill(skill: string): JobSkillDto {
    return this.skills.find(s => s.skill === skill);
  }
}
