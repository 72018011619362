<p-dialog styleClass="edit-dialog" class="p-0 m-0" [modal]="true" [draggable]="false" [closable]="true"
    [visible]="visible" [style]="{'width': dialogWidth, 'height': dialogHeight}" >
    <ng-template pTemplate="header">
        <div class="grid grid-nogutter w-100">
            <div class="col-10 flex justify-content-start align-items-center">
                <p class="text-primary text-ssb text-2xl p-0 m-0">
                    Upload resumes
                </p>
            </div>
            <div class="col-2 flex justify-content-end align-items-center">
                <button pButton pRipple type="button" icon="pi pi-times" (click)="onModalClose()"
                    class="p-button-rounded p-button-text">
                </button>
            </div>
        </div>
    </ng-template>

    <ng-container [formGroup]="uploadResumesForm">
        <div class="mt-3 grid">
            <div class="col-12 lg:col-6">
                <app-labelled-input [ngControl]="uploadResumesForm" [placeholder]="'Type here'" [label]="'Load name'"
                    [name]="'loadName'">
                </app-labelled-input>
            </div>
            <div class="col-12 lg:col-6">
                <input class="hiden" accept=".pdf,.doc,.docx" type="file" id="fileInput" (change)="onFileSelected($event)" multiple
                    #fileInputRef />
                <button [disabled]="!uploadResumesForm.valid" pButton label="Upload" class="primary w-100" pRipple
                    (click)="triggerFileInput(fileInputRef)"></button>
            </div>
        </div>

        <div class="grid">
            <div class="col-12">
                <p *ngIf="selectedFileNames.length > 0">
                    Selected Files: {{ selectedFileNames.join(', ') }}
                </p>
                <p *ngIf="selectedFileNames.length === 0">
                    No files selected.
                </p>
                <p *ngIf="fileErrorMessage">
                    {{ fileErrorMessage }}
                </p>
            </div>
        </div>


    </ng-container>

    <!-- <ng-template pTemplate="footer">
        <div class="mt-3 grid justify-content-end">
            <div class="col-4 flex">
                <button (click)="onHide.emit()" pButton label="Cancel" class="velvet mt-3 mr-3 w-100" pRipple></button>
                <button pButton label="Upload" class="primary mt-3 w-100" pRipple></button>
            </div>
        </div>
    </ng-template> -->
</p-dialog>
