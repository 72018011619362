<div  class="grid grid-nogutter" *ngIf="coverLetter && coverLetter.description; else noCoverLetterTemplate">
    <div class="col-12">
        <p class="text-rb">{{coverLetter.alias}}</p>
    </div>
    <div class="col-12">
        <p class="text-description">{{coverLetter.description}}</p>
    </div>
</div>

<ng-template #noCoverLetterTemplate>
    <div  class="grid grid-nogutter">
        <div class="col-12 flex justify-content-center align-items-center">
            <div class="w-40 flex justify-content-center">
                <p-button  *ngIf="showAddButton"
                           (click)="onClick.emit()"
                           class="primary" label="Cover Letter" 
                           icon="pi pi-plus"  [disabled]="!enabledButton"></p-button>
            </div>
        </div>
    </div>
</ng-template>