<section class="p-3">
  <div class="p-card content-panel p-2">
    <form [formGroup]="contactForm">
      <div class="col-10">
        <p class="title" *ngIf="!title">WHO IS CREATING THIS ACCOUNT?</p>
        <p class="title" *ngIf="title">{{title}}</p>
      </div>
      <div class="mt-3 grid">
        <ng-container [formGroup]="contactForm">
          <div class="col-12 lg:col-5">
            <app-labelled-input [ngControl]="contactForm" [placeholder]="'First Name (English)'" [label]="'First Name'"
                                [name]="'firstName'">
            </app-labelled-input>
          </div>
          <div class="col-12 lg:col-5">
            <app-labelled-input [ngControl]="contactForm" [placeholder]="'Last Name (English)'" [label]="'Last Name'"
                                [name]="'lastName'">
            </app-labelled-input>
          </div>
          <div class="col-12 lg:col-5">
            <app-labelled-input [ngControl]="contactForm" [placeholder]="'First Name (Katakana)'" [label]="'First Name'"
                                [name]="'nativeFirstName'">
            </app-labelled-input>
          </div>
          <div class="col-12 lg:col-5">
            <app-labelled-input [ngControl]="contactForm" [placeholder]="'Last Name (Katakana)'" [label]="'Last Name'"
                                [name]="'nativeLastName'">
            </app-labelled-input>
          </div>
          <div class="col-12 lg:col-5">
            <app-labelled-input [type]="'email'" [ngControl]="contactForm"
                                [placeholder]="'Email'" [label]="'Sign In Email'" [name]="'email'">
            </app-labelled-input>
          </div>
          <div class="col-12 lg:col-5">
            <div class="grid grid-nogutter w-100">
              <label for="dateOfBirth" class="col-3 pr-2 text-right text-rb">Date of Birth</label>
              <div class="col-9">
                <p-calendar id="dateOfBirth"
                            formControlName="dateOfBirth"
                            [maxDate]="maxDate"
                            [showClear]="true"
                            [dateFormat]="CONST.DATE_FORMAT"
                            [showIcon]="true"
                            placeholder="Date of Birth"
                            [style]="{'width':'100%'}"></p-calendar>
              </div>
            </div>
          </div>
          <div class="col-12 lg:col-5">
            <div class="grid grid-nogutter w-100">
              <label for="dateOfBirth" class="col-3 pr-2 text-right text-rb">Gender</label>
              <div class="col-9">
                <app-gender-dropdown [ngControl]="contactForm" [name]="'gender'">
                </app-gender-dropdown>
              </div>
            </div>
          </div>
          <ng-container [formGroup]="contactForm.controls['phoneForm']">
            <div class="col-12 lg:col-5">
              <div class="grid grid-nogutter w-100">
                <label for="phone" class="col-3 pr-2 text-right text-rb">Sign In Phone</label>
                <div class="grid grid-nogutter col-9">
                  <div class="pr-2 col-3">
                    <app-country-dropdown [ngControl]="contactForm.controls['phoneForm']"
                                          [name]="'countryId'"
                                          [items]="countries" *ngIf="countries && countries.length > 0">
                    </app-country-dropdown>
                  </div>
                  <div class="col-9">
                    <input id="phone" type="tel" formControlName="phone" maxlength="10"
                           appOnlyInteger
                           pInputText placeholder="Number" style="width: 100%"/>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </div>
</section>
