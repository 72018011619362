<app-edit-list-dialog [visible]="showEditDialog" [title]="'Edit Professional Experiences'"
                      (onClose)="onClose.emit()"
                      [dialogHeight]="'80'">
  <div class="mt-3 grid">
    <ng-container [formGroup]="previousExperienceForm">
      <div class="grid grid-nogutter p-1" id="experiences">
        <div class="col-12 grid grid-nogutter flex align-items-center mt-1">
          <div class="col-6 mb-2 p-1 pr-5">
            <app-labelled-input [ngControl]="previousExperienceForm"
                                [displayBlock]="true"
                                [textAlign]="TextAlignPosition.LEFT"
                                [placeholder]="'Company Name'" [label]="'Company Name'" [name]="'companyName'">
            </app-labelled-input>
          </div>
          <div class="col-5  mb-2 p-1 pr-5">
            <app-labelled-input [ngControl]="previousExperienceForm"
                                [displayBlock]="true"
                                [textAlign]="TextAlignPosition.LEFT"
                                [placeholder]="'Title'" [label]="'Title'" [name]="'title'">
            </app-labelled-input>
          </div>
        </div>

        <div class="col-6 mb-2 p-1 pr-5">
          <app-labelled-input [ngControl]="previousExperienceForm"
                              [displayBlock]="true"
                              [textAlign]="TextAlignPosition.LEFT"
                              [placeholder]="'Description'" [label]="'Description'" [name]="'description'">
          </app-labelled-input>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <app-labelled-input [ngControl]="previousExperienceForm"
                              [displayBlock]="true"
                              [textAlign]="TextAlignPosition.LEFT"
                              [placeholder]="'Location'" [label]="'Location'" [name]="'location'">
          </app-labelled-input>
        </div>
        <div class="col-12 mb-2 p-1 pr-5">
          <div class="grid grid-nogutter block">
            <label class="col-2 pr-2 text-rb">Reason for Leaving</label>
            <div class="col-12 mt-1">
              <p-dropdown
                [options]="reasonsForLeaving"
                formControlName="leavingReason"
                placeholder="Please Select"
                appendTo="body"
                [(ngModel)]="previousExperienceForm.value.leavingReason"
                optionValue="reasonForLeaving" [style]="{'width':'100%'}">
                <ng-template pTemplate="selectedItem" let-selectedItem>
                  <div class="country-item country-item-value">
                    <p class="text-ssb">{{ selectedItem.title }}</p>
                  </div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div class="country-item">
                    <p class="text-ssb">{{ item.title }}</p>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <div class="grid grid-nogutter block">
            <label class="col-12 pr-2 text-rb">Dates Attended From</label>
            <div class="col-12 mt-1">
              <p-calendar formControlName="from"
                          class="full-width-calendar-input"
                          [maxDate]="maxDateValue"
                          [dateFormat]="CONST.DATE_FORMAT"
                          [showIcon]="true"
                          placeholder="Select Date">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <div class="grid grid-nogutter block">
            <label class="col-12 pr-2 text-rb">Dates Attended To</label>
            <div class="col-12 mt-1">
              <p-calendar formControlName="to"
                          class="full-width-calendar-input"
                          [maxDate]="maxDateValue"
                          [dateFormat]="CONST.DATE_FORMAT"
                          [showIcon]="true"
                          placeholder="Select Date">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="col-12 flex justify-content-end align-items-center pr-5">
          <div class="flex space-x-8">
            <button pButton pRipple icon="pi pi-plus-circle" iconPos="left"
                    (click)="addProfessionalExperience()"
                    type="button" [label]=label class="p-button-outlined" [disabled]="!previousExperienceForm.valid">
            </button>
            <button pButton pRipple icon="pi" iconPos="left"
                    (click)="resetProfessionalExperience()"
                    type="button" label="Reset" class="p-button-outlined py-2 px-4">
            </button>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="previousExperienceSchema">
        <p-table [value]="previousExperienceSchema.previousExperienceDtos"
                 styleClass="p-datatable-striped"
                 [paginator]="true"
                 [rows]="10"
                 [showCurrentPageReport]="true"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 editMode="row"
                 [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="header">
            <tr>
              <th>EMPLOYMENT TITLE & COMPANY NAME</th>
              <th>DESCRIPTION</th>
              <th>EMPLOYMENT PERIOD</th>
              <th>REASON OF LEAVING</th>
              <th>ACTIONS</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-previousExperience let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="previousExperience">
              <td>
                <p class="text-rb">{{ previousExperience.title }}</p>
                <p>{{ previousExperience.companyName }}</p>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ previousExperience.description }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb"
                       *ngIf="previousExperience.from || previousExperience.to">{{ previousExperience.from | date: 'shortDate' }}
                      - {{ previousExperience.to | date: 'shortDate' }}</p>
                    <p class="text-ssb"
                       *ngIf="previousExperience.startDate || previousExperience.endDate">{{ previousExperience.startDate | date: 'shortDate' }}
                      - {{ previousExperience.endDate | date: 'shortDate' }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ getReason(previousExperience.leavingReason).title }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <div class="flex space-x-4">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                          (click)="loadProfessionalExperience(previousExperience.id, ri)"
                          class="p-button-rounded p-button-text py-2 px-4">
                  </button>
                <button pButton pRipple type="button" icon="pi pi-times-circle"
                        (click)="removeProfessionalExperience(previousExperience.id, ri)"
                        class="p-button-rounded p-button-danger p-button-outlined button-border-none">
                </button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </div>
</app-edit-list-dialog>
