import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ApiEventStatus, ApiEventType} from "../../../models/api-event";
import {ApiEventService} from "../../../services/api-event.service";
import {OpentimerJobProfileService} from "../../../services/opentimer-job-profile-service";
import {MessageService} from "primeng/api";
import {EducationDto, EducationDtoSchema} from "../../../models/dto/educations.dto";
import * as moment from "moment/moment";
import {TextAlignPosition} from "../../labelled-input/labelled-input.component";
import {CONST} from "../../../constant";

@UntilDestroy()
@Component({
  selector: 'app-edit-profile-education',
  templateUrl: './edit-profile-education.component.html',
  styleUrls: ['./edit-profile-education.component.scss']
})
export class EditProfileEducationComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() profileId: number;

  @Output() onClose = new EventEmitter();

  @Input() educationSchema: EducationDtoSchema;
  maxDateValue = new Date();
  educationId: number;
  educationIndex: number;

  educationForm = this.formBuilder.group({
    schoolName: this.formBuilder.control('', [Validators.required, Validators.minLength(2)]),
    location: this.formBuilder.control('', [Validators.required, Validators.minLength(2)]),
    studyArea: this.formBuilder.control('', [Validators.minLength(2)]),
    degree: this.formBuilder.control('', [Validators.minLength(2)]),
    from: this.formBuilder.control('', Validators.required),
    to: this.formBuilder.control('', Validators.required),
    description: this.formBuilder.control('', [Validators.required]),
  });


  constructor(
    private formBuilder: FormBuilder,
    private opentimerJobProfileService: OpentimerJobProfileService,
    private messageService: MessageService,
    private apiEventService: ApiEventService
  ) {
  }

  ngOnInit(): void {
    this.getApiEvents();
    if (this.profileId) {
      this.opentimerJobProfileService.getJobProfileEducation(this.profileId);
    }
  }

  protected getApiEvents(): void {
    if (this.apiEventService.event) {
      this.apiEventService.event
        .pipe(untilDestroyed(this))
        .subscribe(event => {
          if (event && event.type === ApiEventType.GET_JOB_PROFILE_EDUCATIONS && event.status === ApiEventStatus.COMPLETED) {
            this.educationSchema = this.opentimerJobProfileService.educationSchema$.value;
          }

          if (event && event.type === ApiEventType.ADD_JOB_PROFILE_EDUCATIONS && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileEducation(this.profileId);
            this.educationForm.reset();
            this.messageService.add({
              severity: 'success',
              summary: 'Education added',
              detail: 'Education is added to this profile'
            });
          }

          if (event && event.type === ApiEventType.DELETE_JOB_PROFILE_EDUCATIONS && event.status === ApiEventStatus.COMPLETED) {
            this.opentimerJobProfileService.getJobProfileEducation(this.profileId);
            this.messageService.add({
              severity: 'success',
              summary: 'Education deleted',
              detail: 'Education is deleted from this profile'
            });
          }
        });
    } else {
      console.error('ApiEventService event is null');
    }
  }

  addEducation() {
    const formValue = this.educationForm.value;
    const educationDto: EducationDto = {
      id: null,
      schoolName: formValue.schoolName,
      location: formValue.location,
      studyArea: formValue.studyArea,
      degree: formValue.degree,
      from: moment(formValue.from).format(CONST.DATE_FORMAT_MOMENT),
      to: moment(formValue.to).format(CONST.DATE_FORMAT_MOMENT),
      description: formValue.description,
    }
    if (this.profileId) {
      this.opentimerJobProfileService.addJobProfileEducation(this.profileId, educationDto);
    } else {
      if (this.educationIndex >=0) {
        console.log(this.educationIndex, educationDto);
        this.educationSchema.educations[this.educationIndex] = educationDto
      }
      else
        this.educationSchema.educations.push(educationDto)
    }
    this.resetEducation()
  }

  removeEducation(educationId: number, index: number) {
    if (this.profileId) {
      this.opentimerJobProfileService.deleteJobProfileEducation(this.profileId, educationId);
    } else {
      this.educationSchema.educations.splice(index, 1)
    }
  }

  resetEducation() {
    this.educationForm.reset()
    this.educationId = null
    this.educationIndex = null
    this.label = 'Add'
  }

  loadEducation(educationId: number, index: number) {
    const education = this.educationSchema.educations[index];
    this.educationForm.reset()
    this.educationId = educationId
    this.educationIndex = index
    this.label = 'Update'
    this.educationForm.patchValue({
      schoolName: education.schoolName,
      location: education.location,
      studyArea: education.studyArea,
      degree: education.degree,
      from: education.startDate ? moment(education.startDate).format(CONST.DATE_FORMAT_MOMENT) : null,
      to: education.endDate ? moment(education.endDate).format(CONST.DATE_FORMAT_MOMENT) : null,
      description: education.description
    })
  }

  protected readonly TextAlignPosition = TextAlignPosition;
  protected readonly CONST = CONST;
  label: string = 'Add';
}
