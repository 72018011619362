export interface ApiEvent {
  type: ApiEventType;
  status: ApiEventStatus;
  title?: string;
  message?: string;
  spinner?: boolean;
  popup?: boolean;
  toast?: boolean;
}

export enum ApiEventStatus {
  DEFAULT,
  IN_PROGRESS,
  COMPLETED,
  ERROR
}

export enum ApiEventType {
  CREATE_ADMIN,
  CREATE_ACCOUNT,
  CREATE_BOOK_OPENTIMER,
  CREATE_HOLIDAY,
  CREATE_OPENTIMER_NORMAL_BANK_ACCOUNT,
  CREATE_SERVICE_CATEGORY,
  CREATE_SKILLS,
  CREATE_SUB_SERVICE_CATEGORY,
  CREATE_PRESET_SHIFT,
  CREATE_CITY,
  CREATE_JOB_PROFILE,
  CREATE_JOB_PROFILE_WITH_RESUME,
  CANCEL_BOOKING_REQUEST,
  CANCEL_INTERVIEW_REQUEST,
  DOWNLOAD_INTERVIEW_RECORDED_VIDEO,
  GET_ACCOUNT_IMAGE,
  GET_ADMINS,
  GET_ADMIN_CURRENT,
  GET_ADMIN_BY_ID,
  GET_ADMIN_ROLES,
  GET_AUTHENTICATED,
  GET_AGENCIES,
  GET_AGENCY_BY_ID,
  GET_AGENCY_BOOKING_ORDERS,
  GET_AGENCY_COMMISSION_PERCENTAGES,
  GET_BOOKINGS,
  GET_BOOKINGS_BY_ID,
  GET_BOOKING_CUSTOMER_RATINGS,
  GET_BOOKINGS_WORKING_TIME_STATISTICS,
  GET_BOOKING_CANCEL_REQUEST_CUSTOMER,
  GET_BOOKING_CANCEL_REQUEST_JOB_PROFILE,
  GET_CITIES,
  GET_CONTACT_BY_ID,
  GET_COUNTRIES,
  GET_COUNTRY_TIMEZONES,
  GET_CREDIT_REQUESTS,
  GET_CUSTOMERS,
  GET_CUSTOMER_BY_ID,
  GET_CUSTOMER_PROFILE_BY_ID,
  GET_CUSTOMER_INVOICES,
  GET_CUSTOMER_RATINGS,
  GET_CUSTOMER_SHIFTS,
  GET_CUSTOMER_WALLET,
  GET_GOOGLE_PREDICTIONS,
  GET_GOOGLE_PLACE,
  GET_HOLIDAYS,
  GET_ID_VERIFICATIONS,
  GET_ID_VERIFICATIONS_BY_IDCARD_TYPE,
  GET_INTERVIEWS,
  GET_INTERVIEWS_BY_ID,
  GET_RECRUITERS,
  GET_OPENTIMERS,
  GET_OPENTIMER_JOB_PROFILE_BY_ID,
  GET_OPENTIMER_JOB_PROFILES,
  GET_OPENTIMER_BOOKING_ORDERS,
  GET_OPENTIMER_BOOKING_WORKING_TIMES,
  GET_OPENTIMER_BOOKING_WORKING_TIMES_BY_DAY,
  GET_OPENTIMER_BY_ID,
  GET_OPENTIMER_LANGUAGE_PROFICIENCIES,
  ADD_OPENTIMER_LANGUAGE_PROFICIENCIES,
  DELETE_OPENTIMER_LANGUAGE_PROFICIENCIES,
  GET_OPENTIMER_OPENTIMER_RECRUITERS,
  GET_OPENTIMER_OPENTIMER_PROFILES,
  GET_OPENTIMER_SUMMARIES,
  GET_OVERTIMES,
  GET_OVERTIME_BY_COUNTRY_CODE,
  GET_PROFICIENCY_LANGUAGES,
  GET_REPORTS,
  GET_REPORTS_BY_ID,
  GET_REPORTS_AGENCY,
  GET_REPORTS_BOOKING,
  GET_REPORTS_CUSTOMER,
  GET_REPORTS_JOB_PROFILE,
  GET_SERVICE_CATEGORIES,
  GET_SERVICE_SUB_CATEGORIES,
  GET_SERVICE_SUB_CATEGORIES_BY_CATEGORY_ID,
  GET_SKILLS,
  GET_PRESET_SHIFTS,
  GET_PRESET_SHIFTS_ACTIVE,
  GET_PRICES,
  GET_REJECTION_REASONS,
  GET_TAX_RATES,
  GET_WALLETS,
  UPDATE_ADMIN,
  UPDATE_ACCOUNT_APPROVAL,
  UPDATE_ACCOUNT_ENABLE,
  UPDATE_ACCOUNT_DISABLE,
  UPDATE_ACCOUNT_DENIAL,
  UPDATE_ACCOUNT_REEVALUATION,
  UPDATE_AGENCY_COMMISSION,
  UPDATE_BOOKING_CANCELLATION_REQUEST_APPROVAL,
  UPDATE_BOOKING_CANCELLATION_REQUEST_REJECTION,
  UPDATE_CITY,
  UPDATE_CITIES,
  UPDATE_CREDIT_APPROVAL,
  UPDATE_CREDIT_DENIAL,
  UPDATE_BATCH_JOB_PROFILE_INFORMATIONS,
  UPDATE_HOLIDAY,
  UPDATE_VERIFICATIONS_BY_IDCARD_TYPE_APPROVE,
  UPDATE_ID_VERIFICATIONS_BY_IDCARD_TYPE_REJECT,
  UPDATE_ID_VERIFICATIONS_BY_IDCARD_TYPE_REMOVE,
  UPDATE_JOB_PROFILE_ACTIVATE,
  UPDATE_JOB_PROFILE_AVAILABILITY,
  UPDATE_JOB_PROFILE_BANK_ACCOUNT,
  UPDATE_JOB_PROFILE_CERTIFICATIONS,
  GET_JOB_PROFILE_CERTIFICATIONS,
  ADD_JOB_PROFILE_CERTIFICATIONS,
  DELETE_JOB_PROFILE_CERTIFICATIONS,
  UPDATE_JOB_PROFILE_COVER_LETTER,
  UPDATE_JOB_PROFILE_DEACTIVATE,
  ADD_JOB_PROFILE_EDUCATIONS,
  DELETE_JOB_PROFILE_EDUCATIONS,
  ANALYZE_JOB_PROFILE_RESUME,
  UPDATE_JOB_PROFILE_EDUCATIONS,
  GET_JOB_PROFILE_EDUCATIONS,
  UPDATE_JOB_PROFILE_HOURLY_RATES,
  GET_JOB_PROFILE_HOURLY_RATES,
  UPDATE_JOB_PROFILE_EXPERIENCE_LEVEL,
  UPDATE_JOB_PROFILE_LOCATIONS,
  UPDATE_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS,
  GET_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS,
  ADD_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS,
  DELETE_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS,
  UPDATE_JOB_PROFILE_PROFESSIONAL_EXPERIENCES,
  GET_JOB_PROFILE_PROFESSIONAL_EXPERIENCES,
  ADD_JOB_PROFILE_PROFESSIONAL_EXPERIENCES,
  DELETE_JOB_PROFILE_PROFESSIONAL_EXPERIENCES,
  UPDATE_JOB_PROFILE_TRAVEL_RESTRICTIONS,
  UPDATE_PRESET_SHIFT_ACTIVATIONS,
  UPDATE_PRESET_SHIFT_NAME,
  UPDATE_REPORT_DETAIL,
  UPDATE_SERVICE_CATEGORY,
  UPDATE_SERVICE_CATEGORIES,
  UPDATE_SERVICE_SUB_CATEGORY,
  UPDATE_SKILL,
  UPDATE_COUNTRY_TIMEZONE,
  UPSERT_AGENCY_ADDRESS,
  UPSERT_AGENCY_INFORMATION,
  UPSERT_AGENCY_NAME,
  UPSERT_ACCOUNT_IMAGE,
  UPSERT_CONTACT_IMAGE,
  UPSERT_PROFILE_IMAGE,
  UPSERT_CONTACT_ADDRESS,
  UPSERT_CONTACT_PERSONAL_INFO,
  UPSERT_CUSTOMER_ADDRESS,
  UPSERT_CUSTOMER_LOCATIONS,
  UPSERT_CUSTOMER_DETAILS,
  UPSERT_CUSTOMER_COUNTRY,
  UPSERT_CUSTOMER_PHONES_EMAILS,
  UPSERT_ID_VERIFICATION_TYPE,
  UPSERT_ID_VERIFICATIONS_BY_IDCARD_TYPE,
  UPSERT_SEARCH_OPENTIMERS,
  UPSERT_SERVICE_CATEGORY_IMAGE,
  UPSERT_OPENTIMER,
  UPSERT_OVERTIMES,
  UPSERT_PHONES_EMAILS,
  UPSERT_PRICES,
  UPSERT_TAX_RATES,
  SIGNIN,
  GET_BULK_RESUMES,
  GET_RESUMES,
  GET_RESUME,
  UPLOAD_RESUMES,
  GET_ADMINS_FOR_RESUME,
}
