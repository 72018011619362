<div class="grid grid-nogutter" *ngIf="jobSkillLevels.length > 0; else noSkillsTemplate">
  <div class="col-12">
    <p-table [value]="jobSkillLevels"
             [paginator]="true"
             [rows]="10"
             [showCurrentPageReport]="true"
             responsiveLayout="scroll"
             currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
             editMode="row"
             [rowsPerPageOptions]="[10,25,50]">
      <ng-template pTemplate="header">
        <tr>
          <th>SKILL NAME</th>
          <th>LEVEL</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-jobSkill>
        <tr>
          <td>
            <p class="text-ssb pl-0" *ngIf="getSkill(jobSkill.skill)">{{ getSkill(jobSkill.skill) | translateBackend }}</p>
            <p class="text-ssb pl-0" *ngIf="!getSkill(jobSkill.skill)">{{ jobSkill.skill }}</p>
          </td>
          <td>
            <p class="text-ssb pl-0">
              <p-chip class="mb-1" [label]="jobSkill.experienceLevel"
                      [class]="chipColor.get(jobSkill.experienceLevel)">
              </p-chip>
            </p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-template #noSkillsTemplate>
  <div  class="grid grid-nogutter">
    <div class="col-12 flex justify-content-center align-items-center">
      <div class="w-40 flex justify-content-center">
        <p-button  *ngIf="showAddButton"
                   (click)="onClick.emit()"
                   class="primary" label="Skill Level"
                   icon="pi pi-plus" [disabled]="!enabledButton"></p-button>
      </div>
    </div>
  </div>
</ng-template>
