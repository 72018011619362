import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { CertificationDtoSchema, CertificationInputDto } from "../models/dto/certification.dto";
import { CoverLetterInputDto } from "../models/dto/cover-letter.dto";
import { EducationDto, EducationDtoSchema } from "../models/dto/educations.dto";
import { ExperienceLevelEnum } from "../models/dto/experience-level.dto";
import { ProfileLocationInputDto } from "../models/dto/location-address.dto";
import {
  HourlyRateDtoSchema,
  JobSkillExperienceDtoSchema,
  JobSubCategoryLevelCreationDto,
  OpentimerAvailabilityDto,
  OpentimerProfileCreationDto,
  OpentimerProfilesSchema,
  OpentimerTravelRestrictionDto
} from "../models/dto/opentimer-job-profile.dto";
import { PreviousExperienceDtoSchema, PreviousExperienceInputDto } from "../models/dto/previous-work-experience.dto";
import { JobSkillExperienceInputDto } from "../models/dto/skill.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { JobSubCategoryLevelDto } from "../models/dto/service-category.dto";

@Injectable({
  providedIn: 'root',
})
export class OpentimerJobProfileService extends AbstractHttpHandler {

  public opentimerProfileSchema$ = new BehaviorSubject<OpentimerProfilesSchema>(null);

  public opentimerJobProfile$ = new BehaviorSubject<OpentimerProfileCreationDto>(null);
  public jobSkillLevels$ = new BehaviorSubject<JobSkillExperienceDtoSchema>(null);
  public hourlyRateSchema$ = new BehaviorSubject<HourlyRateDtoSchema>(null);
  public educationSchema$ = new BehaviorSubject<EducationDtoSchema>(null);
  public previousExperienceSchema$ = new BehaviorSubject<PreviousExperienceDtoSchema>(null);
  public certificatesSchema$ = new BehaviorSubject<CertificationDtoSchema>(null);



  constructor(
    public override http: HttpClient,
    public override apiEventsService: ApiEventService,
    private router: Router,
  ) {
    super(http, apiEventsService);
  }

  getOpentimerJobProfile(opentimerJobProfileId: number) {
    const url = `v2/opentimerProfiles/${opentimerJobProfileId}`;
    const eventType = ApiEventType.GET_OPENTIMER_JOB_PROFILE_BY_ID;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    let params = new HttpParams();

    this.http.get<OpentimerProfileCreationDto>(url, { params })
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response => {
        this.opentimerJobProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  createJobProfile(opentimerId: number, jobSubCategoryLevelCreationDto: JobSubCategoryLevelCreationDto) {
    const url = `v2/opentimerProfiles/${opentimerId}/jobCategories`;
    const eventType = ApiEventType.CREATE_JOB_PROFILE;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, jobSubCategoryLevelCreationDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response => {
        this.opentimerJobProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      });
  }

  createJobProfileWithResume(opentimerId: number, jobSubCategoryLevelCreationDto: JobSubCategoryLevelCreationDto, resumeId: number) {
    const url = `v2/admins/opentimers/${opentimerId}/opentimerProfiles`;
    const eventType = ApiEventType.CREATE_JOB_PROFILE_WITH_RESUME;
    let params = new HttpParams();

    if (resumeId) {
      params = params.append('resumeId', resumeId);
    }
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, jobSubCategoryLevelCreationDto, { params })
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response => {
        this.opentimerJobProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      });
  }

  addJobProfileSkillExperienceLevel(opentimerJobProfileId: number, jobSkillExperienceInputDto: JobSkillExperienceInputDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/skillExperienceLevels`;
    const eventType = ApiEventType.ADD_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, jobSkillExperienceInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }



  updateJobProfileSkillExperienceLevel(opentimerJobProfileId: number, jobSkillExperienceInputDto: any) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/skillExperienceLevels`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<any>(url, jobSkillExperienceInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  addJobProfileEducation(opentimerJobProfileId: number, educationDto: EducationDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/educations`;
    const eventType = ApiEventType.ADD_JOB_PROFILE_EDUCATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, educationDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateJobProfileEducation(opentimerJobProfileId: number, educationDto: any) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/educations`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_EDUCATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<any>(url, educationDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  deleteJobProfileEducation(opentimerJobProfileId: number, educationId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/educations/${educationId}`;
    const eventType = ApiEventType.DELETE_JOB_PROFILE_EDUCATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.delete<OpentimerProfileCreationDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  getJobProfileEducation(opentimerJobProfileId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/educations`;
    const eventType = ApiEventType.GET_JOB_PROFILE_EDUCATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.get<EducationDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.educationSchema$.next(response)
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  getJobProfileSkillExperienceLevel(opentimerJobProfileId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/skillExperienceLevels`;
    const eventType = ApiEventType.GET_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.get<JobSkillExperienceDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.jobSkillLevels$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  deleteJobProfileSkillExperienceLevel(opentimerJobProfileId: number, skillLevelId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/skillExperienceLevels/${skillLevelId}`;
    const eventType = ApiEventType.DELETE_JOB_PROFILE_JOB_SKILL_EXPERIENCE_LEVELS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.delete<OpentimerProfileCreationDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }


  updateJobProfileExperienceLevel(opentimerJobProfileId: number, experienceLevel: ExperienceLevelEnum) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/jobCategories/default`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_EXPERIENCE_LEVEL;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
    let params = new HttpParams();

    if (experienceLevel) {
      params = params.append('experienceLevel', experienceLevel);
    }
    this.http.put<JobSubCategoryLevelDto>(url, null,
      { params })
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateJobProfileHourlyRates(opentimerJobProfileId: number, hourlyRateSchema: HourlyRateDtoSchema) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/hourlyRates`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_HOURLY_RATES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<OpentimerProfileCreationDto>(url, hourlyRateSchema)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.hourlyRateSchema$.next(response.hourlyRateSchema);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  getJobProfileHourlyRates(opentimerJobProfileId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/hourlyRates`;
    const eventType = ApiEventType.GET_JOB_PROFILE_HOURLY_RATES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.get<HourlyRateDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.hourlyRateSchema$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  getJobProfileProfessionalExperiences(opentimerJobProfileId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/professionalExperiences`;
    const eventType = ApiEventType.GET_JOB_PROFILE_PROFESSIONAL_EXPERIENCES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.get<PreviousExperienceDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.previousExperienceSchema$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  addJobProfileProfessionalExperience(opentimerJobProfileId: number, inputDto: PreviousExperienceInputDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/professionalExperiences`;
    const eventType = ApiEventType.ADD_JOB_PROFILE_PROFESSIONAL_EXPERIENCES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<PreviousExperienceDtoSchema>(url, inputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateJobProfileProfessionalExperience(opentimerJobProfileId: number, inputDto: any) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/professionalExperiences`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_PROFESSIONAL_EXPERIENCES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<any>(url, inputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  deleteJobProfileProfessionalExperience(opentimerJobProfileId: number, experienceId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/professionalExperiences/${experienceId}`;
    const eventType = ApiEventType.DELETE_JOB_PROFILE_PROFESSIONAL_EXPERIENCES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.delete<PreviousExperienceDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  getJobProfileCertifications(opentimerJobProfileId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/certifications`;
    const eventType = ApiEventType.GET_JOB_PROFILE_CERTIFICATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.get<OpentimerProfileCreationDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.certificatesSchema$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  deleteJobProfileCertification(opentimerJobProfileId: number, certificationId: number) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/certifications/${certificationId}`;
    const eventType = ApiEventType.DELETE_JOB_PROFILE_CERTIFICATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.delete<OpentimerProfileCreationDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  addJobProfileCertification(opentimerJobProfileId: number, certificationInputDto: CertificationInputDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/certifications`;
    const eventType = ApiEventType.ADD_JOB_PROFILE_CERTIFICATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, certificationInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateJobProfileCertification(opentimerJobProfileId: number, certificationInputDto: any) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/certifications`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_CERTIFICATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<any>(url, certificationInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateJobProfileCoverLetter(opentimerJobProfileId: number, coverLetterInputDto: CoverLetterInputDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/coverLetters`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_COVER_LETTER;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<OpentimerProfileCreationDto>(url, coverLetterInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        // this.opentimerJobProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateJobProfileLocation(opentimerJobProfileId: number, profileLocationInputDto: ProfileLocationInputDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/locations`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_LOCATIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.put<OpentimerProfileCreationDto>(url, profileLocationInputDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateTravelRestriction(opentimerJobProfileId: number, opentimerTravelRestrictionDto: OpentimerTravelRestrictionDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/travelRestrictions`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_TRAVEL_RESTRICTIONS;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, opentimerTravelRestrictionDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        // this.opentimerJobProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  updateAvailability(opentimerJobProfileId: number, opentimerAvailabilityDto: OpentimerAvailabilityDto) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/availabilities`;
    const eventType = ApiEventType.UPDATE_JOB_PROFILE_AVAILABILITY;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<OpentimerProfileCreationDto>(url, opentimerAvailabilityDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async response => {
        // this.opentimerJobProfile$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  getOpentimerProfiles(opentimerId: number) {
    const url = `v2/opentimers/${opentimerId}/opentimerProfiles`;
    const eventType = ApiEventType.GET_OPENTIMER_OPENTIMER_PROFILES;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.get<OpentimerProfilesSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: OpentimerProfilesSchema) => {
        this.opentimerProfileSchema$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
  }

  analyzeResume(opentimerJobProfileId: number, formData: FormData) {
    const url = `v2/admins/opentimerProfiles/${opentimerJobProfileId}/resumes`;
    const eventType = ApiEventType.ANALYZE_JOB_PROFILE_RESUME;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

    this.http.post<void>(url, formData)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async () => {
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
  }

  protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
    return (error: any): T => {
      const errorResponse = error.error;

      let title = Messages.HEADER_GENERIC_ERROR;
      let message = Messages.MESSAGE_GENERIC_ERROR;
      let showDialog = true;
      console.log("OpentimerJobProfileService - Error", error)
      if (errorResponse) {
        const errorCode = errorResponse.error;
        switch (errorCode) {
          case 'invalid_token': {
            title = Messages.HEADER_EXPIRED_SESSION_ERROR;
            message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
            break;
          }
          case 'INVALID_INPUT': {
            title = Messages.HEADER_INVALID_INPUT;
            message = JSON.stringify(errorResponse.constraintViolations);
            break;
          }

          case 'INSUFFICIENT_PERMISSION': {
            title = Messages.HEADER_INSUFFICIENT_PERMISSION;
            message = `${Messages.MESSAGE_ACTION_NOT_ALLOWED}`;
            break;
          }

          case 'DUPLICATE_VALUE': {
            title = Messages.HEADER_PROFILE_ALREADY_CREATED_FOR_SUB_CATEGORY;
            message = `${Messages.MESSAGE_PROFILE_ALREADY_CREATED_FOR_SUB_CATEGORY}`;
            break;
          }

          case 'INVALID_ACCOUNT_STATUS': {
            title = Messages.HEADER_ACCOUNT_STATUS;
            message = `${Messages.MESSAGE_ACCOUNT_STATUS_INVALID}`;
            break;
          }

        }
      }

      const errorToken = error.headers.get('Response_token');
      message = `${message} <br/> Response Token: ${errorToken}`;

      this.apiEventsService.sendEvent({
        type: eventType,
        status: ApiEventStatus.ERROR,
        title,
        message,
        popup: showDialog
      });
      this.unAuthorizedHandler(error)
      return response as T;
    };
  }

  protected unAuthorizedHandler(error: any) {
    if (error.status === 401) {
      this.clearLocalStorage();
      this.router.navigate(['']);
    }
  }

}
