import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AccountDto } from "../models/dto/account.dto";
import { AdminCreationDto } from "../models/dto/admin-creation.dto";
import { AdminDto, AdminPermissionEnum, AdminRoleSchema, AdminSchema, AdminSubmitDto, AdminsSchema } from "../models/dto/admin.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { LocalStorageControl } from "../local-storage.conrol";
import { CONST } from "../constant";
import {ContactDto} from "../models/dto/contact.dto";

@Injectable({
    providedIn: 'root',
})
export class AdminCreationService extends AbstractHttpHandler{

    createdAccount$ = new BehaviorSubject<AccountDto>(undefined);
    adminSchema$ = new BehaviorSubject<AdminSchema>(undefined);
    adminsSchema$ = new BehaviorSubject<AdminsSchema>(undefined);
    adminDto$ = new BehaviorSubject<AdminDto>(undefined);
    adminRolesSchema$ = new BehaviorSubject<AdminRoleSchema>(undefined);
    private loggedInUser = new BehaviorSubject<ContactDto>(undefined);

    private currentAdminSchema$ = new BehaviorSubject<AdminSchema>(undefined);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router,
      ) {
        super(http, apiEventsService);
    }

    adminCreation(admin: AdminCreationDto) {
        const url = `v2/adminCreatedAccounts`
        const eventType = ApiEventType.CREATE_ACCOUNT;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
        this.http.post<AccountDto>(url, admin)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: AccountDto)=> {
            this.createdAccount$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
        });
    }

    createAdmin(adminDto: AdminSubmitDto) {
      const url = `v2/admins`
      const eventType = ApiEventType.CREATE_ADMIN;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.post<AdminSchema>(url, adminDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: AdminSchema)=> {
          this.adminSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    updateAdmin(adminId: number, adminDto: AdminDto) {
      const url = `v2/admins/${adminId}`
      const eventType = ApiEventType.UPDATE_ADMIN;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.put<AdminSchema>(url, adminDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: AdminSchema)=> {
          this.adminSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    getAdmins(page:number = 1,
              limit:number = 10,
              search: string = null) {
      const url = `v2/admins`
      const eventType = ApiEventType.GET_ADMINS;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

      let params = new HttpParams();

      if(limit) {
          params = params.append('limit', limit);
      }

      if(page) {
          params = params.append('page', page);
      }

      if(search !== null && search.length > 0) {
          params = params.append('search', `${search}%`);
      }

      this.http.get<AdminsSchema>(url, {params})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: AdminsSchema)=> {
          this.adminsSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

  getAdminsForResume(search: string = null) {
    const url = `v2/admins/resumeLoaders`
    const eventType = ApiEventType.GET_ADMINS_FOR_RESUME;
    this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});

    let params = new HttpParams();

    if(search !== null && search.length > 0) {
      params = params.append('search', `${search}%`);
    }

    this.http.get<AdminsSchema>(url, {params})
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: AdminsSchema)=> {
        this.adminsSchema$.next(response);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
  }

  getAuthenticated(showSpinner: boolean = true) {
    const url = `v2/admins/current`
    const eventType = ApiEventType.GET_AUTHENTICATED;
    this.apiEventsService.sendEvent({type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: showSpinner});
    this.http.get<AdminSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async (response: AdminSchema) => {
        this.loggedInUser.next(response.admin.contact);
        this.currentAdminSchema$.next(response);
        LocalStorageControl.set(CONST.CURRENT_LOGGED_IN_ADMIN, JSON.stringify(response));
        this.apiEventsService.sendEvent({type: eventType, status: ApiEventStatus.COMPLETED});
      });
  }

    current(showSpinner: boolean = true) {
      const url = `v2/admins/current`
      const eventType = ApiEventType.GET_ADMIN_CURRENT;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: showSpinner});
      this.http.get<AdminSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(async (response: AdminSchema)=> {
          this.setCurrentLoggedInUser(response);
        this.loggedInUser.next(response.admin.contact);
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    getAdminById(adminId: number) {
      const url = `v2/admins/${adminId}`
      const eventType = ApiEventType.GET_ADMIN_BY_ID;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.get<AdminSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: AdminSchema)=> {
          this.adminSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    getAdminRoles() {
      const url = `v2/adminRoles`
      const eventType = ApiEventType.GET_ADMIN_ROLES;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS});
      this.http.get<AdminRoleSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: AdminRoleSchema)=> {
          this.adminRolesSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED });
      });
    }

    get currentLoggedInAdminPermissions(): AdminPermissionEnum[] {
      const currentLoggedInAdmin = this.currentLoggedInAdmin$.value;
      if (currentLoggedInAdmin) {
        return currentLoggedInAdmin.admin.permissions;
      }
      return [];
    }

    private setCurrentLoggedInUser(currentAdminSchema: AdminSchema) {
      this.currentAdminSchema$.next(currentAdminSchema);
      LocalStorageControl.set(CONST.CURRENT_LOGGED_IN_ADMIN, JSON.stringify(currentAdminSchema));
    }

    get currentLoggedInAdmin$(): BehaviorSubject<AdminSchema> {
      if(LocalStorageControl.getParsed(CONST.CURRENT_LOGGED_IN_ADMIN)) {
        this.setCurrentLoggedInUser(LocalStorageControl.getParsed(CONST.CURRENT_LOGGED_IN_ADMIN));
        return this.currentAdminSchema$;
      }
      return this.currentAdminSchema$;
    }

    get currentLoggedInAdminRoles(): string[] {
      const currentLoggedInAdmin = this.currentLoggedInAdmin$.value;
      if(currentLoggedInAdmin) {
        return currentLoggedInAdmin.admin.roles.map(v=>v.role);
      }
      return [];
    }

    hasAccessPermission(allowedPermissions: Array<AdminPermissionEnum> = []): boolean {
      const currentLoggedInAdmin = this.currentLoggedInAdmin$.value;
      let currentUserPermissions: Array<AdminPermissionEnum> = [];
      if (currentLoggedInAdmin) {
        currentUserPermissions = currentLoggedInAdmin.admin.permissions;
      }
      let currentUserRoles: Array<string> = [];
      if (currentLoggedInAdmin) {
        currentUserRoles = currentLoggedInAdmin.admin.roles.map(v => v.role);
      }

      const isSuperAdmin = currentUserRoles.includes('SUPER_ADMIN_ROLE');

      return isSuperAdmin || currentUserPermissions.some(permission => allowedPermissions.includes(permission));
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }

              case 'DUPLICATE_VALUE': {
                title = Messages.HEADER_ACCOUNT_EXIST;
                message = Messages.MESSAGE_ACCOUNT_EMAIL_EXIST;
                break;
              }

            }
         }

         if(error.status === 500) {
                title = Messages.HEADER_ACCOUNT_EXIST;
                message = Messages.MESSAGE_ACCOUNT_EMAIL_EXIST;
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)



          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }

    get currentUser(): Observable<ContactDto> {
      return this.loggedInUser.asObservable();
    }
}
