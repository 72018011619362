import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, catchError } from "rxjs";
import { Messages } from "../messages";
import { ApiEventStatus, ApiEventType } from "../models/api-event";
import { AccountStatus } from "../models/dto/account.dto";
import { NormalBankAccountDto } from "../models/dto/bank.dto";
import { BookingOrderDisplayDtoSchema, BookingStatus } from "../models/dto/booking.dto";
import {LanguageProficiencyDto, LanguageProficiencyDtoSchema} from "../models/dto/language.dto";
import {
  OpentimerCreationDto,
  OpentimerDisplayRecruiterDtoSchema,
  OpentimerListDto,
  OpentimerSignUpDto,
  OpentimersListSchema,
  OpentimersSchema,
  OpentimerViewDto
} from "../models/dto/opentimer.dto";
import { AbstractHttpHandler } from "./abstract-http-handler.service";
import { ApiEventService } from "./api-event.service";
import { WorkingTimeDisplaySchema, WorkingTimeStatus } from "../models/dto/working-time.dto";

@Injectable({
    providedIn: 'root',
})
export class OpentimerService extends AbstractHttpHandler {

    opentimersSchema$ = new BehaviorSubject<OpentimersListSchema>(undefined);
    selectedOpentimer$ = new BehaviorSubject<OpentimerListDto>(undefined);
    opentimerSignUpProfile$ = new BehaviorSubject<OpentimerSignUpDto>(undefined);
    opentimerBookingSchema$ = new BehaviorSubject<BookingOrderDisplayDtoSchema>(undefined);

    opentimerRecruiterSchema$ = new BehaviorSubject<OpentimerDisplayRecruiterDtoSchema>(null);
    opentimerLanguageProficiencyDtoSchema$ = new BehaviorSubject<LanguageProficiencyDtoSchema>(undefined);
    opentimerSummaryDto$ = new BehaviorSubject<OpentimerViewDto>(null);

    opentimerWorkingTimeDisplaySchema$ = new BehaviorSubject<WorkingTimeDisplaySchema>(null);

    constructor(
        public override http: HttpClient,
        public override apiEventsService: ApiEventService,
        private router: Router
      ) {
        super(http, apiEventsService);
    }


    getOpentimers(page:number = 1,
                limit:number = 10,
                search: string = null,
                opentimerAliasLike: string = null,
                accountStatuses: AccountStatus[] = [],
                agencyId: number = null,
                enabled: boolean = null,
                countryId: number = null,
                cityId: number = null,
                serviceCategoryId: number = null,
                serviceSubCategoryId: number = null,
                email: string = null
               ) {
        const url = `v2/opentimers`;
        const eventType = ApiEventType.GET_OPENTIMERS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();


        if(limit) {
          params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        if(agencyId) {
          params = params.append('agencyId', agencyId);
        }

        if(countryId) {
          params = params.append('countryId', countryId);
        }

        if(cityId) {
          params = params.append('cityId', cityId);
        }

        if(serviceCategoryId) {
          params = params.append('serviceCategoryId', serviceCategoryId);
        }

        if(serviceCategoryId) {
          params = params.append('serviceSubCategoryId', serviceSubCategoryId);
        }

        if(email !== null && email.length > 0) {
          params = params.append('email', `${email}`);
        }

        if(search !== null && search.length > 0) {
            params = params.append('fullNameLike', `${search}%`);
        }

        if(opentimerAliasLike !== null && opentimerAliasLike.length > 0) {
          params = params.append('opentimerAliasLike', `${opentimerAliasLike}%`);
        }


        accountStatuses.forEach((accountStatus)=>{
          if(accountStatus) {
            params = params.append('statuses', accountStatus);
          }
        });

        if(enabled !== null) {
          params = params.append('enabled', enabled);
        }

        params = params.append('include', ['TOTAL'].toString());

        this.http.get<OpentimersSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe(async response=>{
            this.opentimersSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    getOpentimerBookingOrders(accountId: number,
                          page:number = 1,
                          limit:number = 10,
                          status: BookingStatus[] = [],
                          startDate: string = null,
                          endDate: string = null,
                          customerNameLike: string = null) {
        const url = `v2/opentimers/${accountId}/bookingOrders`;
        const eventType = ApiEventType.GET_OPENTIMER_BOOKING_ORDERS;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

        let params = new HttpParams();

        if(limit) {
            params = params.append('limit', limit);
        }

        if(page) {
            params = params.append('page', page);
        }

        if(startDate) {
            params = params.append('startDate', startDate);
        }

        if(endDate) {
            params = params.append('endDate', endDate);
        }

        status.forEach((bookingStatus)=>{
            if(bookingStatus) {
                params = params.append('status', bookingStatus);
              }
        });


        if(customerNameLike !== null && customerNameLike.length > 0) {
            params = params.append('customerNameLike', `${customerNameLike}%`);
        }

        this.http.get<BookingOrderDisplayDtoSchema>(url, {params})
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: BookingOrderDisplayDtoSchema)=>{
            this.opentimerBookingSchema$.next(response);
            this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })

    }

    getOpentimerById(accountId: number) {
      const url = `v2/opentimers/${accountId}`;
      const eventType = ApiEventType.GET_OPENTIMER_BY_ID;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
      this.http.get<OpentimerSignUpDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: OpentimerSignUpDto)=>{
          this.opentimerSignUpProfile$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    getOpentimerLanguageProficiencies(opentimerId: number) {
      const url = `v2/opentimers/${opentimerId}/languageProficiencies`;
      const eventType = ApiEventType.GET_OPENTIMER_LANGUAGE_PROFICIENCIES;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.get<LanguageProficiencyDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: LanguageProficiencyDtoSchema)=>{
          this.opentimerLanguageProficiencyDtoSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    addOpentimerLanguageProficiency(opentimerId: number, languageProficiencyDto: LanguageProficiencyDto) {
      const url = `v2/opentimers/${opentimerId}/languageProficiencies`;
      const eventType = ApiEventType.ADD_OPENTIMER_LANGUAGE_PROFICIENCIES;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.post<LanguageProficiencyDtoSchema>(url, languageProficiencyDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: LanguageProficiencyDtoSchema)=>{
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    // add as array
    addOpentimerLanguageProficiencyArray(opentimerId: number, languageProficiencyDto: LanguageProficiencyDtoSchema) {
      const url = `v2/opentimers/${opentimerId}/languageProficiencies/lists`;
      const eventType = ApiEventType.ADD_OPENTIMER_LANGUAGE_PROFICIENCIES;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.post<LanguageProficiencyDtoSchema>(url, languageProficiencyDto)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: LanguageProficiencyDtoSchema)=>{
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    deleteOpentimerLanguageProficiency(opentimerId: number, language: string) {
      const url = `v2/opentimers/${opentimerId}/languageProficiencies/${language}`;
      const eventType = ApiEventType.DELETE_OPENTIMER_LANGUAGE_PROFICIENCIES;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.delete<LanguageProficiencyDtoSchema>(url)
        .pipe(catchError(this.handleErrors(eventType, [])))
        .subscribe((response: LanguageProficiencyDtoSchema)=>{
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
        })
    }

    getOpentimerRecruiters(opentimerId: number) {
      const url = `v2/opentimers/${opentimerId}/recruiters`;
      const eventType = ApiEventType.GET_OPENTIMER_OPENTIMER_RECRUITERS;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.get<OpentimerDisplayRecruiterDtoSchema>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: OpentimerDisplayRecruiterDtoSchema)=>{
          this.opentimerRecruiterSchema$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    getOpentimerSummary(opentimerId: number) {
      const url = `v2/opentimers/${opentimerId}/summaries`;
      const eventType = ApiEventType.GET_OPENTIMER_SUMMARIES;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.get<OpentimerViewDto>(url)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe((response: OpentimerViewDto)=>{
          this.opentimerSummaryDto$.next(response);
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }


    getOpentimerWorkingTimes(apiEventType: ApiEventType,
                            opentimerId: number,
                            bookingOrderId: number = null,
                            startDate: string = null,
                            endDate: string = null,
                            status: Array<WorkingTimeStatus> = [],
                            limit: number = null,
                            page: number = null,
                            lastId: number = null,
                            customerId: number = null,
                            profileId: number = null,) {
      const url = `v2/workingTimes`;
      this.apiEventsService.sendEvent({ type: apiEventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });


      let params = new HttpParams();

      if(limit) {
          params = params.append('limit', limit);
      }

      if(page) {
          params = params.append('page', page);
      }



      if(opentimerId) {
        params = params.append('opentimerId', opentimerId);
      }

      if(bookingOrderId) {
        params = params.append('bookingOrderId', bookingOrderId);
      }

      if(startDate) {
          params = params.append('startDate', startDate);
      }

      if(endDate) {
          params = params.append('endDate', endDate);
      }

      this.http.get<WorkingTimeDisplaySchema>(url, {params})
      .pipe(catchError(this.handleErrors(apiEventType, [])))
      .subscribe((response: WorkingTimeDisplaySchema)=>{
          this.opentimerWorkingTimeDisplaySchema$.next(response);
          this.apiEventsService.sendEvent({ type: apiEventType, status: ApiEventStatus.COMPLETED, spinner: false });
      })
    }

    createNormalBankAccount(opentimerId: number, normalBankAccountDto: NormalBankAccountDto) {
      const url = `v2/opentimers/${opentimerId}/normalBankAccounts`;
      const eventType = ApiEventType.CREATE_OPENTIMER_NORMAL_BANK_ACCOUNT;
      this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });

      this.http.put<OpentimerSignUpDto>(url, normalBankAccountDto)
      .pipe(catchError(this.handleErrors(eventType, [])))
      .subscribe(response=> {
          this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED,  spinner: false});
      });
    }

    upsertOpentimer(accountId: number, opentimerCreationDto: OpentimerCreationDto) {
        const url = `v2/opentimers/${accountId}`;
        const eventType = ApiEventType.UPSERT_OPENTIMER;
        this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.IN_PROGRESS, spinner: true });
        this.http.put<OpentimerSignUpDto>(url, opentimerCreationDto)
          .pipe(catchError(this.handleErrors(eventType, [])))
          .subscribe(response=> {
              this.apiEventsService.sendEvent({ type: eventType, status: ApiEventStatus.COMPLETED,  spinner: false});
          });
    }

    protected handleErrors<T>(eventType: ApiEventType, response?: T): (error: any) => T {
        return (error: any): T => {
          const errorResponse = error.error;

          let title = Messages.HEADER_GENERIC_ERROR;
          let message = Messages.MESSAGE_GENERIC_ERROR;
          let showDialog = true;

          if (errorResponse) {
            const errorCode = errorResponse.error;
            switch (errorCode) {
              case 'invalid_token': {
                title = Messages.HEADER_EXPIRED_SESSION_ERROR;
                message = Messages.MESSAGE_EXPIRED_SESSION_ERROR;
                break;
              }
              case 'INVALID_INPUT': {
                title = Messages.HEADER_INVALID_INPUT;
                message = JSON.stringify(errorResponse.constraintViolations);
                break;
              }
            }
         }

         const errorToken = error.headers.get('Response_token');
         message = `${message} <br/> Response Token: ${errorToken}`;

         this.apiEventsService.sendEvent({
                type: eventType,
                status: ApiEventStatus.ERROR,
                title,
                message,
                popup: showDialog
          });
          this.unAuthorizedHandler(error)
          return response as T;
        };
    }

    protected unAuthorizedHandler(error:any) {
        if(error.status === 401) {
            this.clearLocalStorage();
            this.router.navigate(['']);
        }
    }

}
