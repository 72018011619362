import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CONST } from 'src/app/shared/constant';
import { CountryDto } from 'src/app/shared/models/dto/country.dto';
import { CountryService } from 'src/app/shared/services/country.service';
import { AdminCreationDto } from "../../../shared/models/dto/admin-creation.dto";
import { Gender } from "../../../shared/models/gender";
import { ResumeSchema } from 'src/app/shared/models/dto/resume.dto';

@UntilDestroy()
@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss']
})
export class AddContactComponent implements OnInit {
  @Input() title: string = null
  @Input() resumeSchema: ResumeSchema
  contactForm = this.formBuilder.group({
    firstName: this.formBuilder.control('', [Validators.required]),
    lastName: this.formBuilder.control('', [Validators.required]),
    nativeFirstName: this.formBuilder.control('', [Validators.required]),
    nativeLastName: this.formBuilder.control('', [Validators.required]),
    email: this.formBuilder.control('', [Validators.required, Validators.pattern(CONST.EMAIL_REGEX)]),
    dateOfBirth: this.formBuilder.control(undefined, [Validators.required]),
    gender: this.formBuilder.control('', [Validators.required]),
    phoneForm: this.formBuilder.group({
      countryCode: this.formBuilder.control(''),
      countryId: this.formBuilder.control('', Validators.required),
      phone: this.formBuilder.control('', [Validators.required, Validators.minLength(10),
      Validators.pattern('^[0-9]*$')]),
      type: this.formBuilder.control('MOBILE', [Validators.required]),
    }),
  });

  countries: Array<CountryDto> = [];
  maxDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private countryService: CountryService
  ) { }

  ngOnInit(): void {
    this.getSubscribers();
    this.countryService.getCountries();

    if (this.resumeSchema) {
      this.contactForm.patchValue({
        firstName: this.resumeSchema.extractedData.firstName,
        lastName: this.resumeSchema.extractedData.lastName,
        nativeFirstName: this.resumeSchema.extractedData.nativeFirstName,
        nativeLastName: this.resumeSchema.extractedData.nativeLastName,
        email: this.resumeSchema.extractedData.email,
        dateOfBirth: this.resumeSchema.extractedData.dateOfBirth,
        gender: this.resumeSchema.extractedData.gender
      })

      this.contactForm.controls['phoneForm'].patchValue({
        countryCode: this.resumeSchema.extractedData.phoneCountryCode,
        phone: this.resumeSchema.extractedData.phoneNumber,
        countryId: '',
        type: 'MOBILE'
      })
      this.contactForm.controls['phoneForm'].updateValueAndValidity();
    }
  }

  getSubscribers() {
    this.countryService.countries$
      .pipe(untilDestroyed(this))
      .subscribe((countries: Array<CountryDto>) => {
        this.countries = countries;
        if (countries && countries.length > 0 && this.resumeSchema) {
          const countryId = this.countries.find(v => v.phoneCode === this.resumeSchema.extractedData.phoneCountryCode)?.id;
          this.contactForm.controls['phoneForm'].patchValue({
          countryId: countryId.toString()
        })}
      })
  }

  protected readonly CONST = CONST;

  saveForm(): AdminCreationDto {
    const contactForValue = this.contactForm.value;

    const adminCreation: AdminCreationDto = {
      email: contactForValue.email,
      firstName: contactForValue.firstName,
      lastName: contactForValue.lastName,
      nativeFirstName: contactForValue.nativeFirstName,
      nativeLastName: contactForValue.nativeLastName,
      gender: Gender[contactForValue.gender as keyof typeof Gender],
      dateOfBirth: contactForValue.dateOfBirth,
    }
    const selectedCountry = this.countries.find(v => v.id === Number(contactForValue.phoneForm.countryId));
    adminCreation.signInPhone = {
      countryId: selectedCountry.id,
      extension: selectedCountry.phoneCode,
      number: contactForValue.phoneForm.phone,
      type: contactForValue.phoneForm.type
    };
    return adminCreation;
  }

  isFormValid(): boolean {
    return this.contactForm.valid;
  }
}
