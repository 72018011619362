import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ResumeService } from '../../services/resume.service';
import { ApiEventService } from '../../services/api-event.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ApiEventStatus, ApiEventType } from '../../models/api-event';
import { MessageService } from 'primeng/api';

@UntilDestroy()
@Component({
  selector: 'app-upload-resumes',
  templateUrl: './upload-resumes.component.html',
  styleUrls: ['./upload-resumes.component.scss']
})
export class UploadResumesComponent implements OnInit {

  @Input() visible: boolean;

  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  dialogWidth: string = '50';
  dialogHeight: string = '15';

  uploadResumesForm: FormGroup;

  selectedFiles: File[] = [];
  selectedFileNames: string[] = [];
  fileErrorMessage: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private resumeService: ResumeService,
    private apiEventService: ApiEventService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.dialogWidth = `${this.dialogWidth}vw`;
    this.dialogHeight = `${this.dialogHeight}vw`;
    this.uploadResumesForm =  this.formBuilder.group({
      loadName: this.formBuilder.control('', [Validators.required, Validators.minLength(3)])
    })
    this.getApiEvents()
  }

  getApiEvents() {
    this.apiEventService.event
    .pipe(untilDestroyed(this))
    .subscribe(event=>{
      if(event && event.type === ApiEventType.UPLOAD_RESUMES && event.status === ApiEventStatus.COMPLETED) {
        this.messageService.add({severity:'success', summary:'Resumes uploaded', detail:'Bulk resume files uploaded successfully'});
        this.uploadResumesForm.reset()
        this.selectedFiles = [];
        this.selectedFileNames = [];
        this.fileErrorMessage = '';
        this.onHide.emit()
        this.onSubmit.emit()
      }

      if (event.type === ApiEventType.UPLOAD_RESUMES &&
        event.status === ApiEventStatus.ERROR) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Something happened',
          detail: 'Problem with upload files'
        });
      }
    })
  }

  onFileSelected(event: any): void {
    const fileInput = event.target as HTMLInputElement;
    const files: File[] = Array.from(fileInput.files);

    if (files.length < 1 || files.length > 10) {
      this.fileErrorMessage = 'Please select between 1 and 10 files.';
      this.clearSelection(fileInput);
      return;
    }

    const allowedTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    const validFiles = files.filter(file => allowedTypes.includes(file.type));
    if (validFiles.length !== files.length) {
      this.fileErrorMessage = 'Some files have an invalid type.';
      this.clearSelection(fileInput);
      return;
    }

    this.selectedFiles = validFiles;
    this.selectedFileNames = validFiles.map(file => file.name);
    this.fileErrorMessage = '';
    this.onUpload();
  }

  clearSelection(fileInput: HTMLInputElement) {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    fileInput.value = '';
  }

  triggerFileInput(fileInput: HTMLInputElement): void {
    fileInput.click();
  }

  async onUpload() {
    if (this.selectedFiles.length < 1 || this.selectedFiles.length > 10) {
      return;
    }

    const formData = new FormData();

    this.selectedFiles.forEach((file, index) => {
      // Append each file to the form data with the same key name
      formData.append('resumes', file, this.selectedFiles[index].name);
    });
    this.resumeService.upladBulkResumes(this.uploadResumesForm.value.loadName, formData)
  }

  onModalClose(): void {
    this.uploadResumesForm.reset();
    this.selectedFiles = [];
    this.selectedFileNames = [];
    this.onHide.emit()
  }

}
