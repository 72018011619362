<app-edit-list-dialog [visible]="showEditDialog" [title]="'Edit Certifications'"
                      (onClose)="onClose.emit()"
                      [dialogHeight]="'50'">
  <div class="mt-3 grid">
    <ng-container [formGroup]="certificationForm">
      <div class="col-12 grid grid-nogutter p-1" id="educations">
        <div class="col-12 grid grid-nogutter flex align-items-center mt-1">
          <div class="col-6 mb-2 p-1 pr-5">
            <app-labelled-input [ngControl]="certificationForm"
                                [displayBlock]="true"
                                [textAlign]="TextAlignPosition.LEFT"
                                [placeholder]="'Name'" [label]="'Name'" [name]="'name'">
            </app-labelled-input>
          </div>
          <div class="col-5 mb-2 p-1 pr-5">
            <app-labelled-input [ngControl]="certificationForm"
                                [displayBlock]="true"
                                [textAlign]="TextAlignPosition.LEFT"
                                [placeholder]="'Issuing Organization'" [label]="'Issuing Organization'"
                                [name]="'issuingOrganization'">
            </app-labelled-input>
          </div>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <div class="grid grid-nogutter block">
            <label class="col-12 pr-2 text-rb">Issuing Date</label>
            <div class="col-12 mt-1">
              <p-calendar formControlName="issueDate"
                          class="full-width-calendar-input"
                          [maxDate]="maxDateValue"
                          [dateFormat]="CONST.DATE_FORMAT"
                          [showIcon]="true"
                          placeholder="Select Date">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="col-12 flex justify-content-end align-items-center pr-5">
          <div class="flex space-x-8">
            <button pButton pRipple icon="pi pi-plus-circle" iconPos="left"
                    (click)="addCertification()"
                    type="button" [label]=label class="p-button-outlined" [disabled]="!certificationForm.valid">
            </button>
            <button pButton pRipple icon="pi" iconPos="left"
                    (click)="resetCertification()"
                    type="button" label="Reset" class="p-button-outlined py-2 px-4">
            </button>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="certificationSchema">
        <p-table [value]="certificationSchema.certifications"
                 styleClass="p-datatable-striped"
                 [paginator]="true"
                 [rows]="10"
                 [showCurrentPageReport]="true"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 editMode="row"
                 [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="header">
            <tr>
              <th>NAME</th>
              <th>ISSUING ORGANIZATION</th>
              <th>ISSUE DATE</th>
              <th>ACTIONS</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-certification let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="certification">
              <td>
                <p class="text-ssb">{{ certification.name }}</p>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ certification.issuingOrganization }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ certification.issueDate | date: 'shortDate' }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <div class="flex space-x-4">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                          (click)="loadCertification(certification.id, ri)"
                          class="p-button-rounded p-button-text py-2 px-4">
                  </button>
                <button pButton pRipple type="button" icon="pi pi-times-circle"
                        (click)="removeCertification(certification.id, ri)"
                        class="p-button-rounded p-button-danger p-button-outlined button-border-none">
                </button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </div>
</app-edit-list-dialog>
