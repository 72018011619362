import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {TransportationTypeEnum} from "../../../models/dto/tranportation-type.dto";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {MenuItem} from "primeng/api";
import {
  OpentimerProfileCreationDto,
  OpentimerTravelRestrictionDto,
  OpentimerTravelRestrictionResponseDto
} from "../../../models/dto/opentimer-job-profile.dto";
import {ApiEventService} from "../../../services/api-event.service";
import {OpentimerJobProfileService} from "../../../services/opentimer-job-profile-service";
import {ApiEventStatus, ApiEventType} from "../../../models/api-event";

interface SelectTransportationType {
  transportationType: TransportationTypeEnum;
  name: string;
  isSelected: boolean;
  inputId: string;
  iconUrl: string;
}

interface SelectMinuteTravelTime{
  min: number;
  name: string
}

@UntilDestroy()
@Component({
  selector: 'app-edit-travel-restriction',
  templateUrl: './edit-travel-restriction.component.html',
  styleUrls: ['./edit-travel-restriction.component.scss']
})
export class EditTravelRestrictionComponent implements OnInit {
  @Input() showEditDialog: boolean;
  @Input() profileId: number;
  @Input() opentimerTravelRestrictionDto: OpentimerTravelRestrictionResponseDto
  @Output() onHide = new EventEmitter();
  @Output() onSubmit = new EventEmitter();

  items: MenuItem[] = []

  transportationTypeItems: Array<SelectTransportationType> = [
    {
      transportationType: TransportationTypeEnum.WALKING,
      name: 'Walking',
      isSelected: false,
      inputId: '0',
      iconUrl: '/assets/media/svg/walk-outline.svg'
    },
    {
      transportationType: TransportationTypeEnum.CAR,
      name: 'Car',
      isSelected: false,
      inputId: '1',
      iconUrl: '/assets/media/svg/car-outline.svg'
    },
    {
      transportationType: TransportationTypeEnum.BUS,
      name: 'Bus',
      isSelected: false,
      inputId: '2',
      iconUrl: '/assets/media/svg/bus-outline.svg'
    },
    {
      transportationType: TransportationTypeEnum.TRAIN,
      name: 'Train',
      isSelected: false,
      inputId: '3',
      iconUrl: '/assets/media/svg/train-outline.svg'
    }
  ]

  travelRestrictionForm = this.formBuilder.group({
    id: this.formBuilder.control(null),
    transportationType: this.formBuilder.control(null, [Validators.required]),
    remoteWork: this.formBuilder.control(false, [Validators.required]),
    internationalWork: this.formBuilder.control(false, [Validators.required]),
    travelTime: this.formBuilder.control(null, [Validators.required]),
    travelTimeInterview: this.formBuilder.control(null, [Validators.required]),
    relocate: this.formBuilder.control(false, [Validators.required]),
  });

  minuteSelectionItems: Array<SelectMinuteTravelTime> = [
    {
      min: 10,
      name: '10 mins'
    },
    {
      min: 15,
      name: '15 mins'
    },
    {
      min: 30,
      name: '30 mins'
    },
    {
      min: 45,
      name: '45 mins'
    },
    {
      min: 60,
      name: '60 mins'
    },
  ]

  opentimerJobProfile: OpentimerProfileCreationDto;

  constructor(
    private formBuilder: FormBuilder,
    private apiEventService: ApiEventService,
    private opentimerJobProfileService: OpentimerJobProfileService
  ) { }

  ngOnInit(): void {
    if(this.profileId){
      this.opentimerJobProfileService.getOpentimerJobProfile(this.profileId);
    }

    if(this.opentimerTravelRestrictionDto){
      this.opentimerJobProfile = {...this.opentimerJobProfile, travelRestriction: this.opentimerTravelRestrictionDto}
    }

    this.items = [
      {
        label:'Opentimer',
        url: '/admin-portal/opentimer/',
      },
      {
        label:'Job Profile',
        url: `/admin-portal/opentimer-job-profile/${this.profileId}`,
      },
      {
        label:'Restriction',
        url: `/admin-portal/opentimer-job-profile/${this.profileId}`,
      },
    ];

    this.getApiEvents();
  }

  getApiEvents() {
    this.apiEventService.event
      .pipe(untilDestroyed(this))
      .subscribe(event=>{
        if(event && event.status === ApiEventStatus.COMPLETED && event.type === ApiEventType.GET_OPENTIMER_JOB_PROFILE_BY_ID) {
          this.opentimerJobProfile = this.opentimerJobProfileService.opentimerJobProfile$.value;
          if(this.opentimerJobProfile && this.opentimerJobProfile.travelRestriction) {
            this.setRestrictionForm()
          }
        }
      });

  }

  setRestrictionForm(){
    this.travelRestrictionForm.patchValue({
      id: this.opentimerJobProfile.id || null,
      internationalWork: this.opentimerJobProfile.travelRestriction.internationalWork,
      relocate: this.opentimerJobProfile.travelRestriction.relocate,
      remoteWork: this.opentimerJobProfile.travelRestriction.remoteWork,
      transportationType: this.opentimerJobProfile.travelRestriction.transportationType,
      travelTime: this.opentimerJobProfile.travelRestriction.travelTime,
      travelTimeInterview: this.opentimerJobProfile.travelRestriction.travelTimeInterview
    });
  }

  saveTravelRestriction() {
    const opentimerTravelRestrictionDto: OpentimerTravelRestrictionDto = {
      id: this.travelRestrictionForm.value.id || null,
      internationalWork: this.travelRestrictionForm.value.internationalWork,
      relocate: this.travelRestrictionForm.value.relocate,
      remoteWork: this.travelRestrictionForm.value.remoteWork,
      transportationType: this.travelRestrictionForm.value.transportationType,
      travelTime: this.travelRestrictionForm.value.travelTime,
      travelTimeInterview: this.travelRestrictionForm.value.travelTimeInterview
    }
    this.onSubmit.emit(opentimerTravelRestrictionDto);
    this.showEditDialog = false
  }

}
