<app-edit-list-dialog [visible]="showEditDialog" [title]="'Edit Educations'"
                      (onClose)="onClose.emit()"
                      [dialogHeight]="'80'">
  <div class="mt-3 grid">
    <ng-container [formGroup]="educationForm">
      <div class="grid grid-nogutter p-1" id="educations">
        <div class="col-12 grid grid-nogutter flex align-items-center mt-1">
          <div class="col-6 mb-2 p-1 pr-5">
            <app-labelled-input [ngControl]="educationForm"
                                [displayBlock]="true"
                                [textAlign]="TextAlignPosition.LEFT"
                                [placeholder]="'Name of School'" [label]="'Name of School'" [name]="'schoolName'">
            </app-labelled-input>
          </div>
          <div class="col-5 mb-2 p-1 pr-5">
            <app-labelled-input [ngControl]="educationForm"
                                [displayBlock]="true"
                                [textAlign]="TextAlignPosition.LEFT"
                                [placeholder]="'Location'" [label]="'Location'" [name]="'location'">
            </app-labelled-input>
          </div>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <app-labelled-input [ngControl]="educationForm"
                              [displayBlock]="true"
                              [textAlign]="TextAlignPosition.LEFT"
                              [placeholder]="'Area of Study (Optional)'" [label]="'Area of Study (Optional)'"
                              [name]="'studyArea'">
          </app-labelled-input>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <app-labelled-input [ngControl]="educationForm"
                              [displayBlock]="true"
                              [textAlign]="TextAlignPosition.LEFT"
                              [placeholder]="'Degree (Optional)'" [label]="'Degree (Optional)'" [name]="'degree'">
          </app-labelled-input>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <div class="grid grid-nogutter block">
            <label class="col-12 pr-2 text-rb">Dates Attended From</label>
            <div class="col-12 mt-1">
              <p-calendar formControlName="from"
                          class="full-width-calendar-input"
                          [maxDate]="maxDateValue"
                          [dateFormat]="CONST.DATE_FORMAT"
                          [showIcon]="true"
                          placeholder="Select Date">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="col-6 mb-2 p-1 pr-5">
          <div class="grid grid-nogutter  block">
            <label class="col-12 pr-2 text-rb">Dates Attended To</label>
            <div class="col-12 mt-1">
              <p-calendar formControlName="to"
                          class="full-width-calendar-input"
                          [maxDate]="maxDateValue"
                          [dateFormat]="CONST.DATE_FORMAT"
                          [showIcon]="true"
                          placeholder="Select Date">
              </p-calendar>
            </div>
          </div>
        </div>
        <div class="col-12 mb-2 p-1 pr-5">
          <textarea formControlName="description" cols="100"
                    pInputTextarea placeholder="Description" rows="3" class="description-text-area"></textarea>
        </div>
        <div class="col-12 flex justify-content-end align-items-center pr-5">
          <div class="flex space-x-8">
            <button pButton pRipple icon="pi pi-plus-circle" iconPos="left"
                    (click)="addEducation()"
                    type="button" [label]=label class="p-button-outlined py-2 px-4" [disabled]="!educationForm.valid">
            </button>
            <button pButton pRipple icon="pi" iconPos="left"
                    (click)="resetEducation()"
                    type="button" label="Reset" class="p-button-outlined py-2 px-4">
            </button>
          </div>
        </div>
      </div>
      <div class="col-12" *ngIf="educationSchema">
        <p-table [value]="educationSchema.educations"
                 styleClass="p-datatable-striped"
                 [paginator]="true"
                 [rows]="10"
                 [showCurrentPageReport]="true"
                 responsiveLayout="scroll"
                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                 editMode="row"
                 [rowsPerPageOptions]="[10,25,50]">
          <ng-template pTemplate="header">
            <tr>
              <th>NAME OF SCHOOL</th>
              <th>LOCATION</th>
              <th>AREA OF STUDY</th>
              <th>DEGREE</th>
              <th>DATES ATTENDED</th>
              <th>ACTIONS</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-education let-editing="editing" let-ri="rowIndex">
            <tr [pEditableRow]="education">
              <td>
                <p class="text-ssb">{{ education.schoolName }}</p>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ education.location }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ education.studyArea }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb">{{ education.degree }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                  </ng-template>
                  <ng-template pTemplate="output">
                    <p class="text-ssb" *ngIf="education.from || education.to">{{ education.from | date: 'shortDate' }}
                      - {{ education.to | date: 'shortDate' }}</p>
                    <p class="text-ssb"
                       *ngIf="education.startDate || education.endDate">{{ education.startDate | date: 'shortDate' }}
                      - {{ education.endDate | date: 'shortDate' }}</p>
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align:center">
                <div class="flex space-x-4">
                  <button pButton pRipple type="button" icon="pi pi-pencil"
                          (click)="loadEducation(education.id, ri)"
                          class="p-button-rounded p-button-text py-2 px-4">
                  </button>
                  <button pButton pRipple type="button" icon="pi pi-times-circle"
                          (click)="removeEducation(education.id, ri)"
                          class="p-button-rounded p-button-danger p-button-outlined button-border-none py-2 px-4">
                  </button>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </ng-container>
  </div>
</app-edit-list-dialog>
